var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EditableArea", {
    attrs: {
      value: _vm.currentInstanceDescription,
      apiURL: "/instances/" + this.$route.params.iid,
      successMessage: "Instance description successfully updated!",
      editAttr: "description",
      editAttrFullName: "instance description",
      defaultValue: "Instance description not available",
      color: "white",
      showEditIcon: _vm.isInstanceEditor && _vm.canDeleteInstanceOrEditFields,
      id: parseInt(_vm.$route.params.sid, 10),
      finally: function() {
        this$1.$store.dispatch(
          "spaceStore/fetchSpaceInstances",
          this$1.$route.params.sid
        )
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }